import React from "react";
import Layout from "../../templates/layout";
import SEO from "../../components/seo/seo";
import Hero from "../../components/hero/hero";
import IconCard from "../../components/iconCard/iconCard";
import HaveQuestions from "../../components/haveQuestions/haveQuestions";
import BrandLogo from "../../images/logos/elitek-logo.svg";
import QuestionsIcon from "../../images/icons/icon-questions-elitek.svg";
import HeroIcon from "../../images/icons/elitek_support_icon.svg"
import enrollmentForm from "../../images/icons/elitek/enrollment-form.png"
import spanishEnrollmentForm from "../../images/icons/elitek/spanish-enrollment-form.png";
import patientOverviewFlashcard from "../../images/icons/elitek/patientOverviewIcon.jpg";
import spanishOverviewFlashcard from "../../images/icons/elitek/spanishOverviewFlashcard.jpg";
import ExpandingCard from "../../components/ExpandingCard/ExpandingCard";



// new components
import Isi from "./components/isi";
import ResourceLinks from "./components/ResourceLinks";
import BackToTop from "../../components/backToTop/BackToTop";
import IconCardNew from "../../components/iconCardNew/iconCardNew";
import PrescribingInfo from "../../components/prescribingInfo/prescribingInfo";

const pageContent = () => (
  <Layout pageid="page-forms" pageClass="mobile-height-hero">
    <SEO
      title="Find CareASSIST patient resources for ELITEK® (rasburicase) | Patient Site"
      keywords="CareASSIST, Sanofi, ELITEK® (rasburicase), Patient Site, Resource Support"
      description="Find resources and support options for ELITEK with CareASSIST. See Prescribing Info, including Boxed WARNING"
    />

    <Hero
      headline="Resource support"
      brandLogo={BrandLogo}
      brandAlt="Elitek Logo"
      heroImgClass="hero-icon"
      hasLogo={true}
      hasIcon={true}
      heroIcon={HeroIcon}
      iconAlt="Support Icon"
      hasButton={false}
      btnLink=""
      btnTitle=""
      btnText=""
      btnClass="red"
      copy="Get connected to independent support organizations and resources to support your treatment journey "
      minHeight="h-452"
    />

    <section className="program-download mobile-p-t-0">
      <div className="program-download-inner elitek">
        <div className="expanding-card-content header m-b-30 p-l-35 mobile-p-l-0">
          <h2 className="m-t-0 lh100 mobile-p-l-15">Program essentials</h2>
        </div>
        <div className="grid-cols-sm-1 grid-cols-2 gap-30 p-l-35 p-r-35">
          <IconCard
            icon={enrollmentForm}
            alt="Enrollment Form Icon"
            cardClass="download-card elitek"
            cardTitle="Enrollment form"
            cardText=""
            isExternalLink={true}
            link="../../pdfs/careassist-program-enrollment-form.pdf"
            linkClass="download-icon"
            linkTitle="Download the enrollment form"
            isBlur={true}
            linkId="patient-elitek-resource-enrollment-form"
          />
          <IconCard
            icon={spanishEnrollmentForm}
            alt="Spanish Enrollment Form Icon"
            cardClass="download-card elitek"
            cardTitle="Spanish enrollment form"
            cardText=""
            isExternalLink={true}
            link="../../pdfs/careassist-spanish-enrollment-form.pdf"
            linkClass="download-icon"
            linkTitle="Download the Copay proof of expense form"
            isBlur={true}
            linkId="patient-elitek-resource-spanish-enrollment-form"
          />
          <IconCard
            icon={patientOverviewFlashcard}
            alt="CareASSIST Patient Overview Brochure Icon"
            cardClass="download-card elitek"
            cardTitle="CareASSIST patient overview flashcard"
            cardText=""
            isExternalLink={true}
            link="../../pdfs/careassist-patient-support-program-overview-resource-patient.pdf"
            linkClass="download-icon"
            linkTitle=""
            isBlur={true}
            linkId="patient-elitek-resource-flashcard"
          />
          {/* <IconCard
            icon={spanishOverviewFlashcard}
            alt="Spanish Patient Overview Flashcard Icon"
            cardClass="download-card elitek"
            cardTitle="CareASSIST Spanish patient overview flashcard"
            cardText=""
            isExternalLink={false}
            link="../../pdfs/careassist-patient-support-program-overview-resource-spanish-version.pdf"
            linkClass="download-icon"
            linkTitle=""
          /> */}
        </div>
      </div>
    </section>

    <section className="content-section p-t-20" id="overview">
        <div className="expanding-card-content header">
          <h2 className="fs-32">Online resources*</h2>
        </div>
        <ResourceLinks/>  
        <Isi/>
      <PrescribingInfo linkId="patient-elitek-pi" link="https://products.sanofi.us/elitek/elitek.html" />
      <HaveQuestions icon={QuestionsIcon} />
    </section>
    
    <div className="back-to-top-container"> 
      <BackToTop />
    </div>
  </Layout>
);

export default pageContent;
