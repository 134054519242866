import React from "react";

const Isi = () => {
  return (
    <div className="important-info elitek pt-1">
      <h4 className="mt-0">INDICATION</h4>
      <p>
        ELITEK is indicated for the initial management of plasma uric acid
        levels in patients with leukemia, lymphoma, and solid tumor malignancies
        who are receiving anticancer therapy expected to result in tumor lysis
        and subsequent elevation of plasma uric acid. ELITEK is indicated only
        for a single course of treatment.
      </p>
      <h4 className="m-b-15">IMPORTANT SAFETY INFORMATION</h4>
      <div className="warning">
        <h4 className="color-black">
          WARNING: HYPERSENSITIVITY REACTIONS, HEMOLYSIS, METHEMOGLOBINEMIA, AND
          INTERFERENCE WITH URIC ACID MEASUREMENTS
        </h4>
        
          <p><span className="magenta-bull bold">&bull;</span>
            Hypersensitivity Reactions: ELITEK can cause serious and fatal
            hypersensitivity reactions including anaphylaxis. Immediately and
            permanently discontinue ELITEK in patients who experience a serious
            hypersensitivity reaction.
          </p>
          <p><span className="magenta-bull bold">&bull;</span>
            Hemolysis: Do not administer ELITEK to patients with
            glucose-6-phosphate dehydrogenase (G6PD) deficiency. Immediately and
            permanently discontinue ELITEK in patients developing hemolysis.
            Screen patients at higher risk for G6PD deficiency (e.g., patients
            of African or Mediterranean ancestry) prior to starting ELITEK.{" "}
          </p>
          <p><span className="magenta-bull bold">&bull;</span>
            Methemoglobinemia: ELITEK can result in methemoglobinemia in some
            patients. Immediately and permanently discontinue ELITEK in patients
            developing methemoglobinemia.{" "}
          </p>
          <p><span className="magenta-bull bold">&bull;</span>
            Interference with Uric Acid Measurements: ELITEK enzymatically
            degrades uric acid in blood samples left at room temperature.
            Collect blood samples in prechilled tubes containing heparin and
            immediately immerse and maintain sample in an ice water bath. Assay
            plasma samples within 4 hours of collection.{" "}
          </p>
        
      </div>
      <h4>CONTRAINDICATIONS</h4>
      <p>
        ELITEK is contraindicated in patients with a history of anaphylaxis or
        severe hypersensitivity to rasburicase or in patients with development
        of hemolytic reactions or methemoglobinemia with rasburicase. ELITEK is
        contraindicated in individuals deficient in glucose-6-phosphate
        dehydrogenase (G6PD).
      </p>
      <h4>ADVERSE REACTIONS</h4>
      <p>
        Most common adverse reactions (incidence ≥20%), when used concomitantly
        with anticancer therapy, are vomiting, nausea, fever, peripheral edema,
        anxiety, headache, abdominal pain, constipation, diarrhea,
        hypophosphatemia, pharyngolaryngeal pain, and increased alanine
        aminotransferase.
      </p>
      <h4>USE IN SPECIFIC POPULATIONS</h4>
      
        <p><span className="magenta-bull bold">&bull;</span>
          <b>Pregnancy:</b> Consider the benefits and risks of ELITEK and
          possible risks to the fetus when prescribing ELITEK to a pregnant
          woman
        </p>
        <p><span className="magenta-bull bold">&bull;</span>
          <b>Lactation:</b> Because of the potential for serious adverse
          reactions in the breastfed child, advise patients that breastfeeding
          is not recommended during treatment with ELITEK and for 2 weeks after
          the last dose
        </p>
      
    </div>
  );
};

export default Isi;
