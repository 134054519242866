import React from "react"
import "./iconCardNew.scss"
import InternalButton from "../internalButton/internalButton"
import ModalOverlay from "../modal/modal"
import { Link } from "gatsby"

function IconCardNew({icon, alt, cardClass, cardTitle, cardText, cardList, hasButton, btnLink, btnTitle, btnText, btnClass, hasLink, link, linkTitle, linkText, linkClass, isExternalLink, isModalLink, modalLink, modalLinkId, trackingID, isBlur, linkId}) {
    let cList = '';
    if (cardList !== undefined && cardList !== '') {
        cardList = cardList.split('|');
        // console.log(cardList);
        cList =
            <ul>
                {cardList.map(item => (<li>{item}</li>))}
            </ul>
    }

    return (
      <figure className={"icon-card-new " + cardClass}>
        {icon ? <img src={icon} alt={alt} className={isBlur ? 'blur' : ''} /> : <span style={{marginLeft: ".9rem"}}></span>}
        <figcaption>
          <h4 dangerouslySetInnerHTML={{ __html: cardTitle }} />
          <p>{cardText}</p>
          {cList}

          {hasButton && (
            <InternalButton
              url={btnLink}
              title={btnTitle}
              text={btnText}
              className={btnClass}
              linkId={linkId}
            />
          )}
          {hasLink && (
            <Link id={linkId} to={link} title={linkTitle} className={linkClass}>
              {linkText}
            </Link>
          )}
          {isExternalLink && (
            <a
              href={link}
              title={linkTitle}
              className={linkClass}
              target="_blank"
              rel="noreferrer"
            >
              {linkText}
            </a>
          )}
          {isModalLink && (
            <ModalOverlay
              message={[
                <strong>YOU ARE NOW LEAVING</strong>,
                <br />,
                "SanofiCareASSIST.com",
                <br />,
                <p>
                  Sanofi does not review the information contained on
                  this website and/or database for content, accuracy, or
                  completeness. Use of and access to this information is subject
                  to the terms, limitations, and conditions set by the website
                  and/or database producer. Sanofi makes no
                  representation as to the accuracy or any other aspect of the
                  information contained on such website and/or database, nor
                  does Sanofi necessarily endorse such website and/or
                  database.
                </p>,
              ]}
              buttontxt="Continue"
              link={modalLink}
              trackingID={modalLinkId}
            >
              <button id={modalLinkId} class="view-link modal-trigger">
                view site
              </button>
            </ModalOverlay>
          )}
        </figcaption>
      </figure>
    );
}

export default IconCardNew